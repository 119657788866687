import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Breadcrumbs, Link } from "@nulogy/components";
import { config } from "../../playgrounds/breadcrumbs";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Breadcrumbs`}</h1>
    <p>{`A breadcrumbs component that wraps around links or text to help orient the user within the application.`}</p>
    <Playground {...config} mdxType="Playground" />
    <h2>{`Using with React-Router`}</h2>
    <p>{`The Breadcrumbs component can be used with React Router by using the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { BrowserRouter, Link as ReactRouterLink } from "react-router-dom";
import { Breadcrumbs, Link } from "@nulogy/components";

export const WithReactRouter = () => (
  <BrowserRouter>
    <Breadcrumbs>
      <Link as={ReactRouterLink} to="/">
        Home
      </Link>
      <Link as={ReactRouterLink} to="/Tenants">
        Tenants
      </Link>
    </Breadcrumbs>
  </BrowserRouter>
);
`}</code></pre>
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={config.props} mdxType="PropsTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      